import React, { useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Greet } from "./components/Greet";
import { Person } from "./components/Person";
import { PersonList } from "./components/PersonList";
import { Status } from "./components/Status";
import { Heading } from "./components/Heading";
import { Oscar } from "./components/Oscar";
import { Button } from "./components/Button";
import { Input } from "./components/Input";
import { Container } from "./Container";
import { LoggedIn } from "./components/state/LoggedIn";
// import { User } from "./components/state/User";
import { Counter } from "./components/state/Counter";

import { ThemeContextProvider } from "./components/context/ThemeContext";

import { UserContextProvider } from "./components/context/UserContext";
import { User } from "./components/context/User";

import { Box } from "./components/context/Box";
import COM_Upload_FILE from "./COM/COM_Upload_FILE";

import TextField from "@mui/material/TextField";

function App() {
  const personName = {
    first: "Dora",
    last: "Emon",
  };

  const nameList = [
    {
      first: "br",
      last: "way",
    },
    {
      first: "cl",
      last: "ke",
    },
    {
      first: "pr",
      last: "di",
    },
  ];

  // ref :https://www.youtube.com/watch?v=zLyeWSfTMa8&list=PLC3y8-rFHvwi1AXijGTKM0BKtHzVC-LSK&index=5

  const [fileName, setFileName] = useState("");

  return (
    <>
      {/* <Greet name="tifa" messgeCount={99} isLoggedIn={true} /> */}
      {/* <Greet name="batman" isLoggedIn={true} /> */}
      {/*
      <Person name={personName} />
      <PersonList names={nameList} /> */}
      {/* <Status status="loading" />
      <Heading> Placeholder Text888</Heading>
      <Oscar>
        <Heading>Oscar to the Mars</Heading>
      </Oscar> */}
      {/* <Button handleClick={(e, id) => console.log("e", e, id)} />
      <Input
        value=""
        handleChange={(event) => console.log("e", event.target.value)}
      /> */}

      {/* <Container styles={{ border: "1px solid black", padding: "1rem" }} /> */}

      {/* <User /> */}

      {/* <Counter /> */}
      {/* <UserContextProvider>
        <User />
      </UserContextProvider> */}

      <div
        style={{
          margin: "5rem",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <iframe
          src="https://free.timeanddate.com/countdown/i8ip6ccc/n122/cf107/cm0/cu4/ct0/cs0/ca0/cr0/ss0/cac000/cpc000/pcfff/tcfff/fs100/szw320/szh135/tatFile%20Submit/tac000/tptTime%20since%20Event%20started%20in/tpc000/mac000/mpc000/iso2022-09-27T17:00:00"
          // allowtransparency="true"
          // frameborder="0"
          width="320"
          height="135"
        ></iframe>
        <br />
        <br />
        <br />
        <TextField
          id="outlined-name"
          label="1. Nick Name"
          // value={name}
          onChange={(e) => {
            console.log("xxx", e.target.value);
            setFileName(e.target.value);
          }}
        />
        <br />
        {fileName !== "" ? (
          <COM_Upload_FILE
            P_dirPath={`./uploads/temp data/`}
            P_fileName={`${fileName}.zip`}
            P_BtnLable="(TXT,DOC,PDF,JPG etc)"
          />
        ) : (
          ""
        )}
        <br />
      </div>
    </>
  );
}

export default App;

//https://www.youtube.com/watch?v=rbtTb9hLYS8&list=PLC3y8-rFHvwi1AXijGTKM0BKtHzVC-LSK&index=14
