import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
} from "react";

import axios from "axios";

import Button from "@mui/material/Button";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

export default function COM_Upload_FILE({ P_dirPath, P_fileName, P_BtnLable }) {
  // ---------------------------------- global var

  const [fileExists, setFileExists] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");

  // express-file -----------------------------
  // const [file, setFile] = useState("");
  // const [filename, setFilename] = useState("Choose File");
  const file = useRef("");
  const filename = useRef("");

  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onChange = (e) => {
    file.current = e.target.files[0];
    filename.current = e.target.files[0].name;

    onSubmit(e);

    alert("done");
    window.location.reload();
  };

  const onSubmit = async (e) => {
    // e.preventDefault();
    const formData = new FormData();
    formData.append("file", file.current);
    formData.append("dirpath", P_dirPath);
    formData.append("filename", P_fileName);

    // try {
    // const res = await
    axios
      .post(
        // "http://localhost:7000/api/upload/upload_FILE",
        `${REACT_APP_ECLIPSE_API}/api/upload/upload_FILE`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        if (res.data.status === "successful") {
          setFileExists(true);
        } else {
          setFileExists(false);
        }
      });

    // // Clear percentage
    // setTimeout(() => setUploadPercentage(0), 10000);

    // const { fileName, filePath } = res.data;

    // setUploadedFile({ fileName, filePath });

    // setMessage("File Uploaded");
    // } catch (err) {
    //   if (err.response.status === 500) {
    //     setMessage("There was a problem with the server");
    //   } else {
    //     setMessage(err.response.data.msg);
    //   }
    //   setUploadPercentage(0);
    // }
  };

  const testUrl_Exists = () => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/testURLExist?url=${
          REACT_APP_ECLIPSE_API + P_dirPath.substring(1) + P_fileName
        }`
      )
      .then((res) => {
        console.log("testUrl_Exists", res.data);
        setFileExists(res.data);
        if (res.data === true) {
          //do smtg
        }
        // this.setState({ fileExists: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    testUrl_Exists();
  }, []);

  return (
    <div>
      {/* <form onSubmit={onSubmit}>
        <div className="custom-file mb-4">
          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            onChange={onChange}
          />
        </div>


        <input
          type="submit"
          value="Upload"
          className="btn btn-primary btn-block mt-4"
        />
      </form> */}
      <Button
        fullWidth
        variant="contained"
        component="label"
        style={{
          // marginTop: "-140px",
          borderRadius: "0px",
          backgroundColor: "rgba(143, 255, 199,.5)",
          color: "black",

          // boxShadow: "0 8px 32px 0 rgba(143, 255, 199, 0.4 )",
          // backdropFilter: "blur( 11.0px )",
          // "&::webkit-backdrop-filter": {
          // webkitBackdropFilter: "blur( 18.0px )",
          // },
        }}
      >
        <input
          type="file"
          hidden
          className="custom-file-input"
          id="customFile"
          onChange={onChange}
        />
        <div className="Global_Flex_Column_Pure">
          {fileExists === true ? (
            <>
              <Button
                style={{
                  // marginTop: "-140px",
                  borderRadius: "0px",
                  backgroundColor: "rgba(143, 255, 199,.5)",

                  // boxShadow: "0 8px 32px 0 rgba(143, 255, 199, 0.4 )",
                  // backdropFilter: "blur( 11.0px )",
                  // "&::webkit-backdrop-filter": {
                  // webkitBackdropFilter: "blur( 18.0px )",
                  // },
                }}
                onClick={() => {
                  window.open(
                    `${
                      REACT_APP_ECLIPSE_API +
                      P_dirPath.substring(1) +
                      P_fileName
                    }`
                  );
                }}
              >
                Download
              </Button>
              <div>Re-Upload :</div>
              <div>{P_BtnLable}</div>
            </>
          ) : (
            <>
              <div>Upload</div>
              <div>{P_BtnLable}</div>
            </>
          )}
          <div
            style={{
              color: uploadStatus.includes("Completed") ? "lightgreen" : "red",
            }}
          >
            {uploadStatus}
          </div>{" "}
        </div>
      </Button>

      {/* <button onClick={uploadImage}>Save Pic</button> */}
    </div>
  );
}
